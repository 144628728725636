import type { TokenList } from '@uniswap/token-lists'
import { RUBT, RUTOKEN, USDTTST, USDT } from 'constants/tokens'

const port = window.location.port
const baseUrl = port ?
    `${window.location.protocol}//${window.location.hostname}:${window.location.port}` :
    `${window.location.protocol}//${window.location.hostname}`

export const rubLogoPath = baseUrl + '/R-logo.svg'
export const usdTestLogoPath = baseUrl + '/tethernew_32.svg'

export const REON_LIST: TokenList = {
    "name": "Reon.finance Default",
    "timestamp": "2024-12-12T18:01:30.180Z",
    "version": {
        "major": 2,
        "minor": 0,
        "patch": 0
    },
    "tags": {},
    "logoURI": "",
    "keywords": ["reon", "finance", "rub"],
    "tokens": [
        {
            "chainId": RUTOKEN.chainId,
            "address": RUTOKEN.address,
            "name": RUTOKEN.name ?? "RUBT Token",
            "symbol": RUTOKEN.symbol ?? "RUBT",
            "decimals": RUTOKEN.decimals,
            "logoURI": rubLogoPath
        },
        {
            "chainId": USDT.chainId,
            "address": USDT.address,
            "name": USDT.name ?? "tUSDT",
            "symbol": USDT.symbol ?? "tUSDT",
            "decimals": USDT.decimals,
            "logoURI": usdTestLogoPath
        },
        {
            "chainId": RUBT.chainId,
            "address": RUBT.address,
            "name": RUBT.name ?? "RUBT Token",
            "symbol": RUBT.symbol ?? "RUBT",
            "decimals": RUBT.decimals,
            "logoURI": rubLogoPath
        },
        {
            "chainId": USDTTST.chainId,
            "address": USDTTST.address,
            "name": USDTTST.name ?? "USDT Test",
            "symbol": USDTTST.symbol ?? "USDTTST",
            "decimals": USDTTST.decimals,
            "logoURI": usdTestLogoPath
        }
    ]
}

export const REONLIST_URL: string = "GET_FROM_CACHE"